import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LineupContainer } from '@app/services/boiler-maintenance/containers/lineup/lineup.container';
import { PropertyAgeContainer } from '@app/services-components/order-info/property-age/property-age.container';
import {
    routingDataNextStep,
    routingDataPreviousStep,
    routingDataServiceType,
    routingDataUpsellServices,
    serviceCardTelecom,
} from '@app/services/constants/services.constants';
import { MaintenanceScheduleContainer } from '@app/services-components/fulfillment/maintenance-schedule/maintenance-schedule.container';
import { OverviewContainer } from '@app/services-components/overview/overview.container';
import { TransactionDetailsContainer } from '@app/services-components/fulfillment/transaction-details/transaction-details.container';
import { ContactDetailsContainer } from '@app/services-components/fulfillment/contact-details/contact-details.container';
import { ServiceType } from '@app/services/types/service-type';
import { BoilerMaintenanceThanksContainer } from '@app/services/boiler-maintenance/containers/thanks/boiler-maintenance-thanks.container';

export const routeOfferLineup = '/services/boiler-maintenance/offer-lineup';
export const routeFulfillmentMaintenanceSchedule = '/services/boiler-maintenance/fulfillment/maintenance-schedule';
export const routeFulfillmentTransactionDetails = '/services/boiler-maintenance/fulfillment/transaction-details';
export const routeFulfillmentContactDetails = '/services/boiler-maintenance/fulfillment/contact-details';
export const routeOverview = '/services/boiler-maintenance/overview';
export const routeThanks = '/services/boiler-maintenance/thanks';

const sharedRouteData = {
    [routingDataServiceType]: ServiceType.BoilerMaintenance,
};

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'order-info/property-age',
                component: PropertyAgeContainer,
                data: {
                    ...sharedRouteData,
                    [routingDataNextStep]: routeOfferLineup,
                },
            },
            {
                path: 'offer-lineup',
                component: LineupContainer,
                data: {
                    ...sharedRouteData,
                    [routingDataNextStep]: routeFulfillmentMaintenanceSchedule,
                },
            },
            {
                path: 'fulfillment/maintenance-schedule',
                component: MaintenanceScheduleContainer,
                data: {
                    ...sharedRouteData,
                    [routingDataPreviousStep]: routeOfferLineup,
                    [routingDataNextStep]: routeFulfillmentTransactionDetails,
                },
            },
            {
                path: 'fulfillment/transaction-details',
                component: TransactionDetailsContainer,
                data: {
                    ...sharedRouteData,
                    [routingDataPreviousStep]: routeFulfillmentMaintenanceSchedule,
                    [routingDataNextStep]: routeFulfillmentContactDetails,
                },
            },
            {
                path: 'fulfillment/contact-details',
                component: ContactDetailsContainer,
                data: {
                    ...sharedRouteData,
                    [routingDataPreviousStep]: routeFulfillmentTransactionDetails,
                    [routingDataNextStep]: routeOverview,
                },
            },
            {
                path: 'overview',
                component: OverviewContainer,
                data: {
                    ...sharedRouteData,
                    [routingDataNextStep]: routeThanks,
                },
            },
            {
                path: 'thanks',
                component: BoilerMaintenanceThanksContainer,
                data: {
                    ...sharedRouteData,
                },
            },

            {
                path: '**',
                redirectTo: 'order-info/property-age',
                pathMatch: 'full',
            },
        ]),
    ],
    exports: [RouterModule],
})
export class BoilerMaintenanceRoutingModule {}
