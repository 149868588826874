import { Component } from '@angular/core';
import { ServiceCardConfig } from '@app/services/types/service-type';
import { serviceCardTelecom } from '@app/services/constants/services.constants';
import { ThanksContainer } from '@app/services-components/thanks/thanks.container';

@Component({
    selector: 'app-boiler-maintenance-thanks-container',
    template: ` <app-thanks-container [upsellServices]="upsellServices"></app-thanks-container> `,
    standalone: true,
    imports: [ThanksContainer],
})
export class BoilerMaintenanceThanksContainer {
    public upsellServices: ServiceCardConfig[] = [serviceCardTelecom];
}
